import { useCallback, useContext, useEffect, useRef, useState } from "react"

//Helpers
import { LogError } from "../../../helpers/logger"

//PrimeReact
import { Button } from "primereact/button"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { InputSwitch } from "primereact/inputswitch"
import { ConfirmDialog } from "primereact/confirmdialog" // For <ConfirmDialog /> component
import { confirmDialog } from "primereact/confirmdialog" // For confirmDialog method
import { Panel } from "primereact/panel"

//Styles
import myStyles from "../../../styles/assessments/AssessmentBlock.module.css"
import assessStyles from "../../../styles/assessments/Assessment.module.css"

// Context
import { UserContext } from "../../../context/userContext"

//Assessment modules
import {
  assessmentsApiListMiniPost,
  assessmentsApiScheduleTaskCompletePut,
} from "../assessmentsApi"
import AddAssessment from "./AddAssessment"
import AssessmentForm from "../AssessmentForm/AssessmentForm"
import AssessmentBlockSession from "./AssessmentBlockSession"
import { LoaderSmall } from "../../../components/Loaders"
import AssessmentBlockTask from "./AssessmentBlockTask"

export default function AssessmentBlock({
  assessmentConfig,
  assessmentTaskProfile,
  onSubmitAssessments,
  onCompleteTask,
  onSetParentLoading,
  parentIsLoading,
  onUpdateSessionValues,
  readOnly,
  adminMode, //hides Status column, show a switchButton for Required & hides complete button
  sessionMode, //Submits completed assessments with SOAR ad_hoc_assessment session.
  hideSessionBlock, //Hides the session input block, but will still create session if createSessionOnSubmit === true. Used in Participant portal
}) {
  const userCtx = useContext(UserContext)

  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingAssessments, setIsLoadingAssessments] = useState(true)
  const [sessionLocation, setSessionLocation] = useState(undefined)
  const [sessionDate, setSessionDate] = useState(new Date())
  const [availableAssessments, setAvailableAssessments] = useState(undefined)
  const [showAddAssessment, setShowAddAssessment] = useState(false)
  const [showAssessment, setShowAssessment] = useState(undefined)

  const howToUsePanelRef = useRef()

  const loadAvailableAssessments = useCallback(async () => {
    try {
      setIsLoading(true)
      setIsLoadingAssessments(true)
      const assessmentListing = await assessmentsApiListMiniPost(
        userCtx.tokenAssessments,
        {
          organizationId: userCtx.organization_id,
          enterpriseId: userCtx.enterprise_id,
          includeDisabledAssessments: false,
        }
      )

      setAvailableAssessments(assessmentListing?.assessments)
      setIsLoading(false)
      setIsLoadingAssessments(false)
    } catch (error) {
      LogError("Unable to load the available list of assessments", error)
      userCtx.setNotificationError("Unable to load available assessment list")
      setAvailableAssessments(undefined)
      setIsLoading(false)
      setIsLoadingAssessments(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCtx.organization_id, userCtx.enterprise_id])

  useEffect(() => {
    loadAvailableAssessments()
  }, [loadAvailableAssessments])

  const ActionBodyTemplate = (rowData) => {
    let iconName = rowData.completed_assessment_id ? "edit" : "task"
    let disabled =
      isLoading ||
      readOnly ||
      assessmentTaskProfile?.taskIsDue === false ||
      parentIsLoading

    if (assessmentTaskProfile?.dateCompleted) {
      iconName = "visibility"
      if (!rowData.completed_assessment_id) {
        disabled = true
      }
    }

    return (
      <div className={assessStyles.actionButtonContainer}>
        {!adminMode ? (
          <Button
            type="button"
            onClick={() => {
              setShowAssessment(rowData)
            }}
            className={assessStyles.actionButtonPurple}
            disabled={disabled}
          >
            <span className={`material-icons`}>{iconName}</span>
          </Button>
        ) : null}

        <Button
          type="button"
          onClick={() => {
            const newAssessmentList = [
              ...assessmentConfig.filter(
                (assessment) => assessment !== rowData
              ),
            ]
            onSubmitAssessments(newAssessmentList)
          }}
          className={assessStyles.actionButtonPurple}
          disabled={
            isLoading ||
            readOnly ||
            !rowData.session_assessment || //Only allow deletions of assessments added during the block session
            (rowData.completed_assessment_id && sessionMode) //Disable delete if this is a completed assessment and a soar session is created on submission
          }
          tooltip="Delete Assessment"
          tooltipOptions={{ position: "bottom" }}
        >
          <span className={`material-icons`}>{"delete"}</span>
        </Button>
      </div>
    )
  }

  const StatusBodyTemplate = (rowData) => {
    let pendingLabel = "Pending"

    if (
      assessmentTaskProfile?.dateCompleted &&
      !rowData.completed_assessment_id
    ) {
      pendingLabel = "Declined"
    }

    if (rowData.completed_assessment_id) {
      return (
        <div className={`${assessStyles.badge} ${assessStyles.badgeCompleted}`}>
          Completed
        </div>
      )
    } else if (rowData.required) {
      return (
        <div
          className={`${assessStyles.badge} ${assessStyles.badgePendingRequired}`}
        >
          {pendingLabel}
        </div>
      )
    } else {
      return (
        <div className={`${assessStyles.badge} ${assessStyles.badgePending}`}>
          {pendingLabel}
        </div>
      )
    }
  }

  const RequiredBodyTemplate = (rowData) => {
    if (adminMode) {
      return (
        <div
          className={`${myStyles.columnRequired} ${assessStyles.switchControl}`}
        >
          <InputSwitch
            checked={rowData.required}
            onChange={(e) => {
              const newConfig = [...assessmentConfig]
              const index = newConfig.indexOf(rowData)
              newConfig[index].required = e.value

              onSubmitAssessments(newConfig)
            }}
          />
        </div>
      )
    } else {
      return (
        <div className={myStyles.columnRequired}>
          <span className={`material-icons`}>
            {rowData.required ? "check" : "remove"}
          </span>
        </div>
      )
    }
  }

  const AssessmentNameTemplate = (rowData) => {
    let name = rowData.assessment_name

    if (!name & !isLoading) {
      const loadedAssessment = availableAssessments.find(
        (assessment) => assessment.id === rowData.assessment_id
      )

      if (loadedAssessment) {
        name = loadedAssessment.name
      }
    }

    if (!name && isLoading) {
      return <LoaderSmall />
    } else {
      return <>{name ? name : "Unknown assessment"}</>
    }
  }

  const markTaskAsComplete = async () => {
    try {
      setIsLoading(true)
      onSetParentLoading(true)
      onCompleteTask()
      setIsLoading(false)
      onSetParentLoading(false)
    } catch (error) {
      LogError("Unable to complete task", error)
      userCtx.setNotificationError(
        "Unable to mark assessment task as completed"
      )
      setIsLoading(false)
      onSetParentLoading(false)
    }
  }

  const ScheduledTaskHelperText = () => {
    if (assessmentTaskProfile) {
      return (
        <Panel
          ref={howToUsePanelRef}
          header={"How to use this interface "}
          className={myStyles.assessmentInformationPanel}
          toggleable
          collapsed
        >
          <div className={myStyles.assessmentInformation}>
            <p>
              The assessments listed below have been scheduled for completion.
            </p>
            <p>
              Once done, clicking on "Complete scheduled task" will close this
              scheduled assessments task and mark any unprocessed, optional
              assessments as declined. You will not be able to re-open this
              instance again.{" "}
            </p>
            <p>
              If you would like to return later to complete the assessments, you
              can click on the "Close" button.
            </p>
          </div>
        </Panel>
      )
    } else {
      return null
    }
  }

  return (
    <>
      <ConfirmDialog />
      {showAddAssessment ? (
        <AddAssessment
          availableAssessments={availableAssessments.filter(
            (assessment) =>
              !assessmentConfig?.some(
                (current) => current.assessment_id === assessment.id
              )
          )}
          onClose={() => {
            setShowAddAssessment(false)
          }}
          onAdd={(newAssessments) => {
            let newAssessmentList = [...assessmentConfig, ...newAssessments]
            onSubmitAssessments(newAssessmentList)

            setShowAddAssessment(false)
          }}
        />
      ) : null}
      {showAssessment ? (
        <AssessmentForm
          assessmentId={showAssessment.assessment_id}
          completedAssessmentId={showAssessment.completed_assessment_id}
          taskId={assessmentTaskProfile?.id}
          readOnly={assessmentTaskProfile?.dateCompleted}
          sessionLocation={sessionLocation}
          showForm={true}
          onCancel={() => {
            setShowAssessment(undefined)
          }}
          onSubmit={async (completedAssessmentId) => {
            const index = assessmentConfig.indexOf(showAssessment)
            let newAssessments = [...assessmentConfig]
            newAssessments[index] = {
              ...assessmentConfig[index],
              completed_assessment_id: completedAssessmentId,
            }

            onSubmitAssessments(newAssessments)
            setShowAssessment(undefined)

            //If this is a task and all assessments have been completed, mark the assessment as completed.
            if (
              assessmentTaskProfile &&
              !assessmentTaskProfile.dateCompleted &&
              !newAssessments.some(
                (assessment) => !assessment.completed_assessment_id
              )
            ) {
              confirmDialog({
                message: (
                  <>
                    <p>
                      All assessments completed, do you want to mark the task as
                      done?
                    </p>
                    <br />
                    <p>
                      If not, please manually click on "Complete scheduled task"
                      when ready
                    </p>
                  </>
                ),
                header: "Confirmation",
                icon: "pi pi-exclamation-triangle",
                defaultFocus: "accept",
                accept: async () => {
                  await markTaskAsComplete()
                },
              })
            }
          }}
        />
      ) : null}
      <div
        className={
          sessionMode ? myStyles.mainLayoutWithSession : myStyles.mainLayout
        }
      >
        <div className={`${myStyles.mainLayoutHeader} ${myStyles.title}`}>
          Assessments
        </div>
        <div className={myStyles.mainLayoutAction}>
          {!assessmentTaskProfile ? (
            <Button
              label="Add assessment"
              className={`${assessStyles.button} ${myStyles.mainActionButton} ${myStyles.button}`}
              type="button"
              disabled={
                !availableAssessments || readOnly || isLoadingAssessments
              }
              loading={isLoadingAssessments}
              onClick={() => {
                setShowAddAssessment(true)
              }}
            />
          ) : null}
        </div>
        <div className={myStyles.mainLayoutTask}>
          <AssessmentBlockTask
            taskProfile={assessmentTaskProfile}
            taskCompletedDate={assessmentTaskProfile?.dateCompleted}
          />
        </div>
        <div className={myStyles.mainLayoutSession}>
          {!hideSessionBlock &&
          (!assessmentTaskProfile || !assessmentTaskProfile?.dateCompleted) ? (
            <AssessmentBlockSession
              locationId={sessionLocation}
              onLocationIdChange={(newLocation) => {
                setSessionLocation(newLocation)
                if (onUpdateSessionValues) {
                  onUpdateSessionValues({
                    locationId: newLocation,
                    sessionDate: sessionDate,
                  })
                }
              }}
              sessionDate={sessionDate}
              onSessionDateChange={(newSessionDate) => {
                setSessionDate(newSessionDate)
                if (onUpdateSessionValues) {
                  onUpdateSessionValues({
                    locationId: sessionLocation,
                    sessionDate: newSessionDate,
                  })
                }
              }}
              readOnly={readOnly}
            />
          ) : null}
        </div>
        <div className={myStyles.mainLayoutBody}>
          <ScheduledTaskHelperText />
          <DataTable
            className={assessStyles.dataTable}
            value={assessmentConfig}
          >
            <Column header={"Assessment name"} body={AssessmentNameTemplate} />
            {!adminMode ? (
              <Column
                header={"Status"}
                body={StatusBodyTemplate}
                className={myStyles.statusColumn}
              />
            ) : null}

            <Column
              header={"Required"}
              body={RequiredBodyTemplate}
              className={myStyles.requiredColumn}
            />
            <Column
              body={ActionBodyTemplate}
              className={myStyles.actionColumn}
            />
          </DataTable>
        </div>
      </div>
    </>
  )
}
