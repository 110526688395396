import { useContext } from "react"
import _ from "lodash"

// Context
import { UserContext } from "../../../context/userContext"

//Formik
import { Form, Formik } from "formik"
import * as Yup from "yup"
import FormikErrorNotification from "../../../helpers/formikInputs/FormikErrorNotification"

//Assessment components
import AssessmentFormMatrix from "./AssessmentFormMatrix"
import AssessmentFormGroup from "./AssessmentFormGroup"
import AssessmentFormQuestion from "./AssessmentFormQuestion"
import {
  validateMultiValueType,
  validateSingeValueType,
} from "./helpers/conditionalQuestionValidator"

//SOAR components
import { LogError } from "../../../helpers/logger"

//styling
import myStyles from "../../../styles/assessments/AssessmentForm.module.css"
import assessStyles from "../../../styles/assessments/Assessment.module.css"

//PrimeReact
import { Button } from "primereact/button"
import { LoaderMedium } from "../../../components/Loaders"
import elementTypes from "../ManagementForm/FormBuilder/Elements/elementTypes"
import { isValidPhoneNumber } from "react-phone-number-input"

//Custom Participant form, do not replace with soar.dev.client AssessmentForm
export default function AssessmentFormQuestionContainer({
  questions,
  answers,
  alwaysAllowSubmit,
  onSubmitValues,
  readOnly,
  disable,
  displayQuestions,
  displayAnswers,
  displayAnswerValues,
  displayScore,
  onCancel,
  completedAssessment,
}) {
  const userCtx = useContext(UserContext)

  const getConditionalRequired = (conditionalAnswer, answerList) => {
    let sourceAnswer = answerList.find(
      (source) => source.questionId === conditionalAnswer.conditionalSource
    )

    let conditionResult = undefined

    if (sourceAnswer.type === "radio") {
      conditionResult = validateMultiValueType(
        conditionalAnswer,
        sourceAnswer.multiValues
      )
    } else {
      conditionResult = validateSingeValueType(
        conditionalAnswer,
        sourceAnswer.value
      )
    }

    if (!sourceAnswer) {
      LogError(
        "Missing source question detected, defaulting field to required",
        conditionalAnswer
      )
      userCtx.setNotificationError(
        "Missing source question detected, defaulting field to required"
      )
    }

    return conditionResult ? conditionResult.required : true
  }

  Yup.addMethod(Yup.array, "validateAssessment", function () {
    return this.test(
      "validateAssessmentAnswers",
      "",
      async function (answerArray) {
        const errors = []

        answerArray.forEach((answer, index) => {
          //check if the answer is required and has an answer
          let isRequired = answer.required

          if (answer.conditionalSource) {
            isRequired = getConditionalRequired(answer, answerArray)
          }

          if (isRequired) {
            if (
              answer.type === elementTypes.checkboxElementId ||
              answer.type === elementTypes.matrixCheckboxElementId
            ) {
              if (
                answer.multiValues.filter(
                  (value) => value.value && value.value !== "false"
                ).length === 0
              ) {
                answer.multiValues.forEach((multiValue, secondIndex) => {
                  errors.push(
                    this.createError({
                      path: `${this.path}[${index}].multiValues[${secondIndex}].value`,
                      message: "",
                    })
                  )
                })

                errors.push(
                  this.createError({
                    path: `${this.path}[${index}].value`,
                    message:
                      "Please specify an answer for this required question",
                  })
                )
              }
            } else if (!answer.value) {
              errors.push(
                this.createError({
                  path: `${this.path}[${index}].value`,
                  message:
                    "Please specify an answer for this required question",
                })
              )
            }
          }

          if (
            answer.type === elementTypes.phoneElementId &&
            answer.value &&
            !isValidPhoneNumber(answer.value, "US")
          ) {
            errors.push(
              this.createError({
                path: `${this.path}[${index}].value`,
                message: "The specified value is not a valid US phone number",
              })
            )
          } else if (
            answer.type === elementTypes.emailElementId &&
            answer.value
          ) {
          }
        })

        if (!_.isEmpty(errors)) {
          throw new Yup.ValidationError(errors)
        }

        return true
      }
    )
  })

  const validationSchema = Yup.object().shape({
    answers: Yup.array()
      .of(
        Yup.object().shape({
          questionId: Yup.string(),
          type: Yup.string(),
          conditionalDisplay: Yup.string().nullable(),
          conditionalRequired: Yup.string().nullable(),
          conditionalSource: Yup.string().nullable(),
          conditionalType: Yup.string().nullable(),
          conditionalValue: Yup.string().nullable(),
          value: Yup.string()
            .nullable()
            .when("type", {
              is: elementTypes.emailElementId,
              then: Yup.string().nullable().email("Invalid e-mail address"),
            }),
          multiValues: Yup.array().of(
            Yup.object().shape({
              value: Yup.string().nullable(),
            })
          ),
        })
      )
      .validateAssessment(),
  })

  const RenderQuestions = ({ questions, values, onSetFieldValue }) => {
    if (readOnly && displayQuestions === false) {
      return null
    } else {
      return (
        <>
          {questions.map((question) => {
            if (question.matrix) {
              return (
                <AssessmentFormMatrix
                  matrix={question.matrix}
                  answers={values.answers}
                  onSetFieldValue={onSetFieldValue}
                  key={question.id}
                  readOnly={readOnly || disable}
                  displayAnswers={displayAnswers}
                  displayAnswerValues={displayAnswerValues}
                  displayScore={displayScore}
                />
              )
            } else if (question.questionGroup) {
              return (
                <AssessmentFormGroup
                  group={question.questionGroup}
                  answers={values.answers}
                  onSetFieldValue={onSetFieldValue}
                  key={question.id}
                  readOnly={readOnly || disable}
                  displayAnswers={displayAnswers}
                  displayAnswerValues={displayAnswerValues}
                  displayScore={displayScore}
                />
              )
            } else if (question.question) {
              return (
                <AssessmentFormQuestion
                  question={question.question}
                  answers={values.answers}
                  onSetFieldValue={onSetFieldValue}
                  key={question.id}
                  readOnly={readOnly || disable}
                  displayAnswers={displayAnswers}
                  displayAnswerValues={displayAnswerValues}
                  displayScore={displayScore}
                />
              )
            } else {
              return <></>
            }
          })}
        </>
      )
    }
  }

  return (
    <Formik
      initialValues={answers}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onSubmitValues(values)
      }}
    >
      {({ isSubmitting, dirty, values, setFieldValue, errors }) => (
        <Form autoComplete="off" className={myStyles.form}>
          <FormikErrorNotification />

          <div className={myStyles.formLayout}>
            <div className={myStyles.formLayoutBody}>
              {!questions ? (
                <LoaderMedium />
              ) : (
                <RenderQuestions
                  questions={questions}
                  values={values}
                  onSetFieldValue={setFieldValue}
                />
              )}
            </div>
            <div className={myStyles.formLayoutFooter}>
              <div className={myStyles.buttonContainer}>
                {!readOnly ? (
                  <Button
                    label={completedAssessment ? "Submit changes" : "Submit"} //Note: Participant assessment form questions only allow submit, not next page
                    type={"submit"}
                    disabled={
                      disable ||
                      ((!dirty || isSubmitting) && !alwaysAllowSubmit)
                    }
                    loading={isSubmitting}
                    className={assessStyles.button}
                  />
                ) : null}
                <Button
                  type="button"
                  label={readOnly ? "Close" : "Cancel"}
                  onClick={() => {
                    onCancel()
                  }}
                  className={assessStyles.button}
                />
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
