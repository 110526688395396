import { DateTime } from "luxon"

//Helpers
import { LogError } from "../../../../helpers/logger"

//Force the date to UTC and set the time to midday
export function getAssessmentDateOnlyISO(jsDate) {
  let date = DateTime.fromJSDate(jsDate)

  date = date
    .setZone("utc", { keepLocalTime: true })
    .set({ hour: 12, minute: 0, second: 0 })

  return date.toISO()
}

//Force the date to local as is (No tz conversion)
export function getAssessmentDateOnlyFromIso(isoDate) {
  const localDate = DateTime.now()

  try {
    let date = DateTime.fromISO(isoDate)

    date = date
      .toUTC()
      .setZone(localDate.toFormat("z"), { keepLocalTime: true })

    return date
  } catch (error) {
    LogError("Unable to convert Assessment ISO to JSDate", error)
    return undefined
  }
}

//Force the time to UTC and set the date to 1900-01-01
export function getAssessmentTimeOnlyISO(jsDate) {
  let date = DateTime.fromJSDate(jsDate)

  date = date
    .setZone("utc", { keepLocalTime: true })
    .set({ year: 1900, month: 1, day: 1, second: 0 })

  return date.toISO()
}

//Force the time to local as is (No tz conversion)
export function getAssessmentTimeOnlyFromIso(isoDate) {
  const localDate = DateTime.now()

  try {
    let date = DateTime.fromISO(isoDate)

    date = date
      .toUTC()
      .setZone(localDate.toFormat("z"), { keepLocalTime: true })

    return date
  } catch (error) {
    LogError("Unable to convert Assessment ISO to JSDate", error)
    return undefined
  }
}
