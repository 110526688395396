//Formik
import FormikCheckboxInput from "../../../helpers/formikInputs/FormikCheckboxInput"
import FormikRadioButtonInput from "../../../helpers/formikInputs/FormikRadioButtonInput"

//styling
import myStyles from "../../../styles/assessments/AssessmentForm.module.css"

export default function AssessmentFormQuestionMatrix({
  question,
  answers,
  valueFieldName,
  readOnly,
}) {
  let labelMinWidth = 25
  let optionWidth

  if (!question?.options) {
    labelMinWidth = 80
    optionWidth = 20
  } else {
    optionWidth = (100 - labelMinWidth) / question.options.length
  }

  const GetOption = ({ label, option }) => {
    const answer = answers.find((answer) => answer.questionId === label.id)

    if (!answer) {
      return <>answer not found</>
    } else {
      const answerIndex = answers.indexOf(answer)
      let valueName = `answers[${answerIndex}].${valueFieldName}`

      if (answer.type === "matrixcheckbox") {
        const multiValueEntry = answer.multiValues.find(
          (value) => value.optionId === option.id
        )
        const multiValueIndex = answer.multiValues.indexOf(multiValueEntry)

        if (multiValueEntry) {
          valueName = `answers[${answerIndex}].multiValues[${multiValueIndex}].${valueFieldName}`
        } else {
          return <>answer not found</>
        }
      }

      return (
        <div className={myStyles.matrixQuestionOption}>
          {question.type === "matrix" ? (
            <FormikRadioButtonInput
              name={valueName}
              options={[{ text: "", optionId: option.id }]}
              optionLabel={"text"}
              optionValue={"optionId"}
              disabled={readOnly}
              className={myStyles.matrixQuestionOptionRadio}
            />
          ) : (
            <FormikCheckboxInput
              checkboxLabel={""}
              name={valueName}
              disabled={readOnly}
            />
          )}
        </div>
      )
    }
  }

  return (
    <table className={myStyles.matrixQuestionTable}>
      <thead>
        <tr>
          <th style={{ minWidth: `${labelMinWidth}%` }}></th>
          {question.options.map((option) => (
            <th
              className={myStyles.matrixQuestionOptionHeader}
              style={{ width: `${optionWidth}%` }}
            >
              {option.text}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {question.questionLabels.map((label) => (
          <tr>
            <td className={myStyles.matrixQuestionOptionTextColumn}>
              {label.text}
            </td>
            {question.options.map((option) => (
              <td className={myStyles.matrixQuestionOptionColumn}>
                <GetOption label={label} option={option} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
