const addressElementId = "address"
const checkboxElementId = "checkbox"
const dateElementId = "date"
const descriptionElementId = "description"
const dropdownElementId = "dropdown"
const emailElementId = "email"
const groupElementId = "group"
const headingElementId = "heading"
const matrixElementId = "matrix"
const matrixCheckboxElementId = "matrixcheckbox"
const numberElementId = "number"
const paragraphElementId = "paragraph"
const phoneElementId = "phone"
const radioElementId = "radio"
const textElementId = "textinput"
const timeElementId = "time"

const basicElements = [
  {
    id: textElementId,
    label: "Short text",
    icon: "short_text",
  },
  {
    id: paragraphElementId,
    label: "Paragraph",
    icon: "segment",
  },
  {
    id: dropdownElementId,
    label: "Dropdown",
    icon: "arrow_drop_down_circle",
  },

  {
    id: radioElementId,
    label: "Single choice",
    icon: "radio_button_checked",
  },
  {
    id: checkboxElementId,
    label: "Multiple choice",
    icon: "check_box",
  },
  {
    id: numberElementId,
    label: "Number",
    icon: "numbers",
  },
  {
    id: dateElementId,
    label: "Date",
    icon: "calendar_month",
  },
  {
    id: timeElementId,
    label: "Time",
    icon: "schedule",
  },
]

const specialElements = [
  {
    id: groupElementId,
    label: "Section",
    tooltip: "A collapsible grouping of elements",
    icon: "menu_open",
  },
  {
    id: headingElementId,
    label: "Heading",
    tooltip: "A heading element",
    icon: "title",
  },
  {
    id: descriptionElementId,
    label: "Description",
    tooltip: "A description block",
    icon: "description",
  },
  {
    id: emailElementId,
    label: "E-mail",
    icon: "alternate_email",
  },
  {
    id: addressElementId,
    label: "Address",
    icon: "location_on",
  },
  {
    id: phoneElementId,
    label: "Phone",
    icon: "call",
  },
  {
    id: matrixElementId,
    label: "Matrix",
    tooltip: "A collapsible grouping of elements",
    icon: "table",
  },
]

const hiddenTypes = [
  {
    id: matrixCheckboxElementId,
    label: "Matrix",
    tooltip: "A collapsible grouping of elements",
    icon: "table",
  },
]

const elementTypes = {
  addressElementId: addressElementId,
  checkboxElementId: checkboxElementId,
  dateElementId: dateElementId,
  dropdownElementId: dropdownElementId,
  emailElementId: emailElementId,
  groupElementId: groupElementId,
  headingElementId: headingElementId,
  descriptionElementId: descriptionElementId,
  matrixElementId: matrixElementId,
  matrixCheckboxElementId: matrixCheckboxElementId,
  numberElementId: numberElementId,
  paragraphElementId: paragraphElementId,
  phoneElementId: phoneElementId,
  radioElementId: radioElementId,
  textElementId: textElementId,
  timeElementId: timeElementId,
  basicElements,
  specialElements,
  hiddenTypes,
  findTypeProfile: (typeId) => {
    const typeProfile = [
      ...basicElements,
      ...specialElements,
      ...hiddenTypes,
    ].find((type) => type.id === typeId)

    return typeProfile
  },
  isMatrixType: (type) => {
    return [matrixCheckboxElementId, matrixElementId].includes(type)
  },
  propertiesConfig: {
    hasWidth: [
      textElementId,
      paragraphElementId,
      dropdownElementId,
      radioElementId,
      checkboxElementId,
      numberElementId,
      dateElementId,
      timeElementId,
      emailElementId,
      addressElementId,
      phoneElementId,
      headingElementId,
      descriptionElementId,
    ],
    hasHeight: [paragraphElementId, headingElementId],
    hasDefault: [
      dropdownElementId,
      radioElementId,
      checkboxElementId,
      dateElementId,
      timeElementId,
    ],
    hasOptions: [
      dropdownElementId,
      radioElementId,
      checkboxElementId,
      matrixElementId,
      matrixCheckboxElementId,
    ],
    hasQuestionLabels: [matrixElementId, matrixCheckboxElementId],
    hasMinValue: [numberElementId, dateElementId, timeElementId],
    hasMaxValue: [numberElementId, dateElementId, timeElementId],
    hasSize: [headingElementId, descriptionElementId],
    hasAlignment: [
      headingElementId,
      descriptionElementId,
      radioElementId,
      checkboxElementId,
    ],
    conditionalDisplayAlwaysOn: [
      descriptionElementId,
      groupElementId,
      headingElementId,
    ],
    hasRequiredIndicator: [
      addressElementId,
      checkboxElementId,
      dateElementId,
      dropdownElementId,
      emailElementId,
      numberElementId,
      paragraphElementId,
      phoneElementId,
      radioElementId,
      textElementId,
      timeElementId,
    ],
  },
}

module.exports = elementTypes
