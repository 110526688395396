import { useField } from "formik"
import { InputTextarea } from "primereact/inputtextarea"

//PrimeReact
import { Skeleton } from "primereact/skeleton"
import { usePlacesWidget } from "react-google-autocomplete"

//Style
import styles from "../../styles/Form-Inputs.module.css"

const AddressInput = ({ name, onChangeCallback, rows, disabled }) => {
  const googlePlacesApiKey = process.env.REACT_APP_REFERRALS_GOOGLE_MAPS_API_KEY

  const [field, , helpers] = useField(name)

  const { ref } = usePlacesWidget({
    apiKey: googlePlacesApiKey,
    options: {
      types: ["geocode"],
      componentRestrictions: { country: "us" },
    },
    onPlaceSelected: (places) => {
      helpers.setValue(places.formatted_address)
    },
  })

  return (
    <InputTextarea
      ref={ref}
      className={"block"}
      style={{ width: "100%" }}
      value={field.value}
      onChange={(e) => {
        helpers.setValue(e.target.value)

        if (onChangeCallback) {
          onChangeCallback(e.target.value)
        }
      }}
      rows={rows}
      autoResize
      disabled={disabled}
    />
  )
}

export default function FormikAddressInput({
  name,
  label,
  helpText,
  placeholderText,
  required,
  isInitialLoad,
  rows,
  disabled,
  onChangeCallback,
}) {
  let placeholder = placeholderText
  if (required && !placeholder) {
    placeholder = name
  }

  const [, meta] = useField(name)

  const InputField = () => {
    if (isInitialLoad) {
      return (
        <Skeleton
          className={meta.touched && meta.error ? "p-invalid block" : "block"}
          height="42px"
        />
      )
    } else {
      return (
        <AddressInput
          name={name}
          disabled={disabled}
          onChangeCallback={onChangeCallback}
          rows={rows}
        />
      )
    }
  }

  const LabelField = () => {
    if (isInitialLoad) {
      return <Skeleton width="10rem" height="1rem" />
    } else {
      return (
        <label>
          {label} {required ? <span style={{ color: "red" }}> * </span> : null}
        </label>
      )
    }
  }

  return (
    <div className={`${styles.field} fieldContainer`}>
      {LabelField()}
      <div className="col-12 md:col-10">
        {helpText ? (
          <div style={{ marginBottom: "10px" }}>
            <small style={{ color: "gray" }}>{helpText}</small>
          </div>
        ) : null}
        {InputField()}

        {meta.touched && meta.error ? (
          <label id={`${name}-error`} className={styles.errFieldMsg}>
            {meta.error}
          </label>
        ) : null}
      </div>
    </div>
  )
}
