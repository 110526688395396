//styling
import { Panel } from "primereact/panel"
import assessStyles from "../../../styles/assessments/Assessment.module.css"
import AssessmentFormQuestion from "./AssessmentFormQuestion"
import { useEffect, useRef } from "react"

export default function AssessmentFormGroup({
  group,
  answers,
  readOnly,
  onSetFieldValue,
  displayAnswers,
  displayAnswerValues,
  displayScore,
}) {
  const groupRef = useRef(undefined)

  useEffect(() => {
    if (groupRef.current) {
      if (group.expanded === false) {
        groupRef.current.collapse()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupRef])

  return (
    <Panel
      header={group.name}
      toggleable
      className={`${assessStyles.groupContainer}`}
      ref={groupRef}
    >
      <div
        className={
          group.alignment === "horizontal"
            ? assessStyles.groupContainerHorizontal
            : ""
        }
      >
        {group.questions.map((question) => {
          return (
            <AssessmentFormQuestion
              question={question}
              answers={answers}
              onSetFieldValue={onSetFieldValue}
              key={question.id}
              readOnly={readOnly}
              displayAnswers={displayAnswers}
              displayAnswerValues={displayAnswerValues}
              displayScore={displayScore}
            />
          )
        })}
      </div>
    </Panel>
  )
}
