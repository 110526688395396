import { useContext } from "react"

//styling
import assessStyles from "../../../styles/assessments/Assessment.module.css"

//Assessment components
import AssessmentFormQuestionInput from "./AssessmentFormQuestionInput"

// Context
import { UserContext } from "../../../context/userContext"
import {
  validateMultiValueType,
  validateSingeValueType,
} from "./helpers/conditionalQuestionValidator"
import { LogError } from "../../../helpers/logger"
import elementTypes from "../ManagementForm/FormBuilder/Elements/elementTypes"

export default function AssessmentFormQuestion({
  question,
  answers,
  readOnly,
  displayAnswers,
  displayAnswerValues,
  displayScore,
}) {
  const userCtx = useContext(UserContext)

  const values = answers?.filter((answer) => answer.questionId === question.id)
  const answerIndex = values.length > 0 ? answers.indexOf(values[0]) : -1

  let displaySetting = true
  let requiredSetting = question.required

  let questionTextWidth = "100%"
  let questionTextAlignment = "left"
  let Text = <>{question.text}</>

  const GetDescriptionText = ({ className }) => {
    return <label className={className}>{question.text}</label>
  }

  if (
    [elementTypes.headingElementId, elementTypes.descriptionElementId].includes(
      question.type
    )
  ) {
    questionTextWidth = question.width || "100%"
    questionTextAlignment = question.optionAlignment

    switch (question.height) {
      case 1:
        Text =
          elementTypes.headingElementId === question.type ? (
            <h3>{question.text}</h3>
          ) : (
            <GetDescriptionText
              className={assessStyles.descriptionElementSmall}
            />
          )
        break
      case 2:
        Text =
          elementTypes.headingElementId === question.type ? (
            <h2>{question.text}</h2>
          ) : (
            <GetDescriptionText
              className={assessStyles.descriptionElementMedium}
            />
          )
        break
      case 3:
        Text =
          elementTypes.headingElementId === question.type ? (
            <h1>{question.text}</h1>
          ) : (
            <GetDescriptionText
              className={assessStyles.descriptionElementLarge}
            />
          )
        break
      default:
        break
    }
  }

  if (question.conditionalSource) {
    let sourceAnswer = answers.find(
      (answer) => answer.questionId === question.conditionalSource
    )

    if (sourceAnswer) {
      let conditionResult = undefined

      if (
        [
          elementTypes.checkboxElementId,
          elementTypes.matrixCheckboxElementId,
        ].includes(sourceAnswer.type)
      ) {
        conditionResult = validateMultiValueType(
          question,
          sourceAnswer.multiValues
        )
      } else {
        conditionResult = validateSingeValueType(question, sourceAnswer.value)
      }

      if (!conditionResult) {
        LogError(
          "Unable to validate conditional setup, ignoring conditional setup",
          question
        )
        userCtx.setNotificationError(
          "Unable to validate conditional setup, ignoring conditional setup"
        )
        requiredSetting = true
      } else {
        requiredSetting = conditionResult.required
        displaySetting = conditionResult.display
      }
    } else {
      LogError(
        "Missing source question detected, ignoring conditional setup",
        question
      )
      userCtx.setNotificationError(
        "Missing source question detected, ignoring conditional setup"
      )
      requiredSetting = true
    }
  }

  const calculateScore = () => {
    let score = 0
    let showScore = false
    const myAnswers = answers.filter(
      (answer) => answer.questionId === question.id
    )

    myAnswers.forEach((answer) => {
      const answerType = answer.type.toLowerCase()

      if (!["textinput", "date"].includes(answerType)) {
        if (["dropdown", "radio"].includes(answerType)) {
          let answerOption = answer.multiValues.filter(
            (option) => option.optionId === answer.value
          )
          if (answerOption.length > 0) {
            score = score + (answerOption[0].scoreValue || 0)
          }
        } else if (answerType === "number" && answer.scoreFromAnswer) {
          score = score + (+answer.value || 0)
        } else if (answerType === "checkbox") {
          let answerOption = answer.multiValues.filter((option) => option.value)
          if (answerOption.length > 0) {
            score = score + (answerOption[0].scoreValue || 0)
          }
        }
        showScore = true
      }
    })

    return showScore ? (
      <>
        <span className={assessStyles.scoreLabel}>Score:</span>{" "}
        <span className={assessStyles.scoreValue}>{score}</span>
      </>
    ) : null
  }

  if (!displaySetting) {
    return null
  }

  return (
    <div
      className={
        displayScore
          ? assessStyles.questionLayoutWithScore
          : assessStyles.questionLayout
      }
      style={{ width: question.width || "100%" }}
    >
      <div
        className={`${assessStyles.questionLayoutName} ${
          [
            elementTypes.headingElementId,
            elementTypes.descriptionElementId,
          ].includes(question.type)
            ? assessStyles.noMarginBottom
            : ""
        }`}
      >
        <div
          className={` ${questionTextAlignment}`}
          style={{ width: questionTextWidth }}
        >
          {Text}
          {requiredSetting ? (
            <span className={assessStyles.required}>{" *"}</span>
          ) : null}
          {question.helpText ? (
            <div>
              <small style={{ color: "gray" }}>{question.helpText}</small>
            </div>
          ) : null}
        </div>
      </div>
      {displayScore ? (
        <div className={assessStyles.questionLayoutScore}>
          {calculateScore()}
        </div>
      ) : null}
      {displayAnswers !== false ? (
        <div
          className={`${assessStyles.questionLayoutBody} ${
            question.optionAlignment === "horizontal"
              ? assessStyles.horizontalQuestion
              : ""
          }`}
        >
          <AssessmentFormQuestionInput
            answerIndex={answerIndex}
            answers={answers}
            question={question}
            readOnly={readOnly}
            displayAnswerValues={displayAnswerValues}
          />
        </div>
      ) : null}
    </div>
  )
}
